<template data-app>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="card">
      <div class="filters my-2" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          {{ $t('Views.AdminPublications.title')
          }}<v-btn
            color="#0c2c7c"
            dark
            class="mb-2"
            style="float: right"
            @click.prevent="handleCreate()"
            >{{ $t('Views.AdminPublications.createButton') }}
          </v-btn>
        </h3>
        <label>{{ $t('Views.AdminPublications.filterText') }}:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="my-2"
          ><v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                :label="$t('Views.AdminPublications.filterSearch')"
                v-model="filters.search"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                :label="$t('Views.AdminPublications.filterDate')"
                clearable
                clear-icon="mdi-close-circle"
                v-model="filters.date"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                :placeholder="$t('Views.AdminPublications.filterType')"
                v-model="filters.type"
                :options="typeOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-col cols="12" lg="1" md="2" sm="6" style="padding: 1px 2px 1px 2px">
              <multiselect
                style="min-width: 80px"
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                :placeholder="$t('Views.AdminPublications.filterActive')"
                v-model="filters.active"
                :options="activeOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-btn
              color="#0c2c7c"
              style="min-width: auto; position: absolute; right: 50px; height: 25px"
              dark
              class="px-1 py-1"
              v-if="filterActivated"
              @click.prevent="
                filterActivated = false
                cleanFilters()
                getPublications()
              "
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#0c2c7c"
                  style="min-width: auto; position: absolute; right: 10px; height: 25px"
                  dark
                  class="px-1 py-1"
                  @click.prevent="
                    filterActivated = true
                    getPublications()
                  "
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Views.AdminPublications.filterButtonText') }}</span>
            </v-tooltip>
          </v-row>
        </v-form>
      </div>
      <simple-list
        :title="''"
        :dense="false"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="true"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="true"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" :height="'75%'" :width="'80%'" :adaptive="true">
      <h3 class="text-center" v-if="flow == 'create'">
        {{ $t('Views.AdminPublications.createText') }}
      </h3>
      <h3 class="text-center" v-if="flow == 'edit'">
        {{ $t('Views.AdminPublications.editText') }}
      </h3>
      <div class="card" style="padding: 2rem; margin: 20px">
        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                :rules="[(v) => !!v || 'Requerido']"
                :items="types"
                :item-text="'name'"
                :item-value="'code'"
                v-model="type"
                :label="$t('Views.AdminPublications.labelType')"
              ></v-select></v-col
            ><v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              v-if="type == 'events' || type == 'schedule'"
            >
              <v-select
                :rules="[(v) => !!v || 'Requerido']"
                :items="modalityOptions"
                :item-text="'name'"
                :item-value="'code'"
                v-model="modality"
                :label="$t('Views.AdminPublications.labelModality')"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.required, rules.maxTitle]"
                :label="$t('Views.AdminPublications.labelTitle')"
                v-model="title"
                counter
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              v-if="type == 'events' || type == 'schedule'"
            >
              <v-text-field
                :rules="[rules.required, rules.maxTitle]"
                :label="$t('Views.AdminPublications.labelAddress')"
                v-model="address"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-textarea
                counter
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminPublications.labelShortDescription')"
                v-model="shortDesc"
              ></v-textarea>
            </v-col>
            <v-col cols="12 mt-2">
              <vue-editor
                id="editor"
                :placeholder="$t('Views.AdminPublications.labelDescription')"
                class="text-box"
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="content"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.required]"
                :label="
                  type === 'events'
                    ? $t('Views.AdminPublications.labelDateStart')
                    : $t('Views.AdminPublications.labelDate')
                "
                v-model="date"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              v-if="type == 'events' || type == 'schedule'"
            >
              <v-text-field
                :label="$t('Views.AdminPublications.labelDateEnd')"
                :rules="[rules.required]"
                v-model="expiredAt"
                clearable
                clear-icon="mdi-close-circle"
                :persistent-hint="true"
                :hint="$t('Views.AdminPublications.labelExpiredText')"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-row no-gutters>
                <span style="width: 30%"
                  >{{ $t('Views.AdminPublications.labelCountry') }}:</span
                >
                <multiselect
                  style="width: 70%"
                  track-by="id"
                  :custom-label="countryName"
                  :placeholder="$t('Views.AdminPublications.placeholder')"
                  v-model="selectedCountries"
                  :options="countries"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                ></multiselect
              ></v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-checkbox
                :label="$t('Views.AdminPublications.labelActive')"
                v-model="active"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-row>
                <a
                  style="width: 40%"
                  :href="item.cover.route"
                  v-if="flow == 'edit' && item.cover && item.cover.route"
                  target="_blank"
                  ><v-img :src="item.cover.route"></v-img
                ></a>

                <v-file-input
                  style="width: 60%"
                  v-model="image"
                  chips
                  clearable
                  clear-icon="mdi-close-circle"
                  ref="file"
                  show-size
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                  :label="
                    flow == 'create'
                      ? $t('Views.AdminPublications.labelFile1')
                      : $t('Views.AdminPublications.labelFile2')
                  "
                ></v-file-input
              ></v-row>
            </v-col>
          </v-row>

          <v-row style="margin: 0px">
            <v-col cols="12"
              ><h5 class="text-center">
                {{ $t('Views.AdminPublications.labelAuthorData') }}
              </h5></v-col
            >
            <v-row class="w-100"
              ><v-col cols="12" lg="4" md="6" sm="12"
                ><v-checkbox
                  v-model="internalAuthor"
                  @change="
                    internalAuthor ? (externalAuthor = false) : (externalAuthor = true)
                  "
                  :label="$t('Views.AdminPublications.labelInternalUser')"
                ></v-checkbox
              ></v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <multiselect
                  :disabled="!internalAuthor"
                  track-by="id"
                  label="name"
                  :placeholder="$t('Views.AdminPublications.placeholderAuthor')"
                  v-model="userId"
                  @close="getCompanies()"
                  :custom-label="labelName"
                  :options="users"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                ></multiselect></v-col
              ><v-col cols="12" lg="4" md="6" sm="12">
                <multiselect
                  :disabled="!internalAuthor"
                  track-by="id"
                  label="name"
                  :placeholder="$t('Views.AdminPublications.placeholderCompany')"
                  v-model="companyId"
                  :custom-label="labelName"
                  :options="companies"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                  hint="Opcional"
                ></multiselect></v-col
            ></v-row>
            <v-row class="w-100"
              ><v-col cols="12" lg="4" md="6" sm="12"
                ><v-checkbox
                  v-model="externalAuthor"
                  @change="
                    externalAuthor ? (internalAuthor = false) : (internalAuthor = true)
                  "
                  :label="$t('Views.AdminPublications.labelExternallUser')"
                ></v-checkbox
              ></v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  :disabled="internalAuthor"
                  :rules="!internalAuthor ? [rules.required] : []"
                  :label="$t('Views.AdminPublications.labelAuthor')"
                  v-model="author"
                ></v-text-field></v-col
            ></v-row>
          </v-row>
        </v-form>
      </div>

      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            :dark="valid"
            style="margin: auto"
            v-if="flow == 'create'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="createItem()"
          >
            {{ $t('Views.AdminPublications.createButton') }}
          </v-btn>
          <v-btn
            :dark="valid"
            style="margin: auto"
            v-if="flow == 'edit'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="saveItem()"
          >
            {{ $t('Views.AdminPublications.saveButton') }}
          </v-btn>

          <v-btn style="margin: auto" dark color="#0c2c7c" @click="reset">
            {{ $t('Views.AdminPublications.cleanButton') }}
          </v-btn>
          <v-btn
            dark
            style="margin: auto"
            color="#0c2c7c"
            @click="$modal.hide('crudModal')"
          >
            {{ $t('Views.AdminPublications.cancelButton') }}
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'
  import Multiselect from 'vue-multiselect'
  import { VueEditor } from 'vue2-editor'

  export default {
    name: 'Publications',
    components: {
      SimpleList,
      Multiselect,
      VueEditor,
    },
    data() {
      return {
        config: {
          headers: {},
        },
        loading: false,
        types: [
          { code: 'news', name: 'Noticias' },
          { code: 'events', name: 'Eventos' },
          { code: 'schedule', name: 'Agenda' },
        ],
        modalityOptions: [
          { code: 'site', name: 'Presencial' },
          { code: 'remote', name: 'Virtual' },
          { code: 'remote-site', name: 'Virtual - Presencial' },
        ],
        items: [],
        users: [],
        companies: [],
        selectedCountries: [],
        countries: [],
        page: 1,
        pages: 1,
        item: {},
        filterActivated: false,
        filters: {
          search: '',
          type: null,
          author: null,
          outstanding: null,
          date: null,
          active: null,
        },
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activas' },
          { id: 0, name: 'Inactivas' },
        ],
        typeOptions: [
          { id: 'news', name: this.$t('Views.AdminPublications.filterTypeOption1') },
          { id: 'events', name: this.$t('Views.AdminPublications.filterTypeOption2') },
          { id: 'schedule', name: this.$t('Views.AdminPublications.filterTypeOption3') },
        ],
        coverOptions: [
          { code: 'square', name: 'Cuadrada' },
          { code: 'horizontal', name: 'Horizontal' },
          { code: 'vertical', name: 'Vertical' },
        ],
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        active: false,
        content: '',
        title: '',
        userId: '',
        countryId: '',
        author: '',
        address: '',
        shortDesc: '',
        modality: null,
        typeCover: null,
        companyId: '',
        order: 1,
        type: 'news',
        contentType: 'text',
        internalAuthor: true,
        externalAuthor: false,
        image: null,
        mainImage: null,
        date: new Date().toISOString().split('T')[0],
        expiredAt: null,
        loadingText: 'Cargando registros',
        valid: true,
        descriptionRules: [
          (v) => !!v || 'Requerido',
          (v) => v.length <= 100 || 'Debe poser menos de 100 caracteres',
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 140 || 'Debe poser menos de 140 caracteres',
          maxTitle: (v) => v.length <= 80 || 'Debe poser menos de 80 caracteres',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
        },
      }
    },
    created() {
      this.getPublications()
      this.getUsers()
      this.getCountries()
    },
    computed: {
      categoryType() {
        switch (this.type) {
          case 'schedule':
            return '4'
          case 'news':
            return '3'
          case 'events':
            return '2'
          default:
            return '1'
        }
      },
      headers() {
        return [
          { text: this.$t('Views.AdminPublications.headerType'), value: 'typeText' },
          {
            text: this.$t('Views.AdminPublications.headerTitle'),
            value: 'title',
            width: '35%',
          },
          { text: this.$t('Views.AdminPublications.headerAuthor'), value: 'author' },
          { text: this.$t('Views.AdminPublications.headerDate'), value: 'date' },
          { text: this.$t('Views.AdminPublications.headerActive'), value: 'activeText' },
          {
            text: '',
            value: 'actions',
            align: 'center',
          },
        ]
      },
      orderOptions() {
        return [
          {
            id: 1,
            name: this.$t('Views.AdminPublications.orderOption1'),
            order: 'created_at',
            by: 'desc',
          },
          {
            id: 2,
            name: this.$t('Views.AdminPublications.orderOption2'),
            order: 'created_at',
            by: 'asc',
          },
          {
            id: 3,
            name: this.$t('Views.AdminPublications.orderOption3'),
            order: 'title',
            by: 'asc',
          },
          {
            id: 4,
            name: this.$t('Views.AdminPublications.orderOption4'),
            order: 'updated_at',
            by: 'desc',
          },
          {
            id: 5,
            name: this.$t('Views.AdminPublications.orderOption5'),
            order: 'date',
            by: 'desc',
          },
        ]
      },
      orderBy() {
        return {
          id: 1,
          name: this.$t('Views.AdminPublications.orderOption1'),
          order: 'created_at',
          by: 'desc',
        }
      },
    },
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getPublications()
      },
      fullName({ name, lastname, email }) {
        if (name && name != 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      cleanFilters() {
        this.filters = {
          search: '',
          type: null,
          author: null,
          // outstanding: null,
          date: null,
          active: null,
        }
      },
      isURL(str) {
        if (!str || str === '') return true
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },
      async getPublications() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let dateString = ''
          let typeString = ''
          let outstandingString = ''
          let activeString = ''
          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.date && this.filters.date != '') {
            dateString = '&date=' + this.filters.date
          }
          if (this.filters.type && this.filters.type.name) {
            typeString = '&type=' + this.filters.type.id
          }
          // if (this.filters.outstanding && this.filters.outstanding.id !== null) {
          //   outstandingString = '&outstanding=' + this.filters.outstanding.id
          // }
          if (this.filters.active && this.filters.active.id !== null) {
            activeString = '&active=' + this.filters.active.id
          }
          queryParams =
            activeString + searchString + dateString + typeString + outstandingString
        }
        axios
          .get('publications?page=' + this.page + orderString + queryParams)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              x.active ? (x.activeText = 'Si') : (x.activeText = 'No')
              switch (x.type) {
                case 'news':
                  x.typeText = 'Noticias'
                  break
                case 'events':
                  x.typeText = 'Eventos'
                  break
                case 'schedule':
                  x.typeText = 'Agenda'
                  break
                default:
                  x.typeText = ''
                  break
              }
              x.date = x.date.split(' ')[0]
            })
            this.loading = false
          })
      },

      labelName({ name, lastname, email }) {
        return `${name ? name : ''} ${lastname ? lastname : ''} | ${email ? email : ''}`
      },

      async getCountries() {
        axios.get('countries?limit=300' + this.countryId).then((response) => {
          this.countries = this.countries.concat(response.data)
        })
      },

      async getCompanies() {
        let userStr = ''
        if (this.userId != '') {
          userStr = '&user_id=' + this.userId.id
        }
        axios.get('companies?type=1&limit=100' + userStr).then((response) => {
          this.companies = response.data
          if (this.item.company_id) {
            this.companyId = this.companies.find(
              (company) => company.id === this.item.company_id
            )
          }
        })
      },

      countryName({ name, iso2 }) {
        if (name && iso2) {
          return `${name} (${iso2})`
        } else if (name) {
          return name
        }
      },
      async getUsers() {
        axios.get('users?limit=1000').then((response) => {
          this.users = response.data
          this.users.map((x) => {
            x.fullName = x.name + ' ' + x.lastname + ' | ' + x.email
          })
        })
      },

      async getAssignedUser(userId) {
        axios.get('users/' + userId).then((response) => {
          this.userId = response.data
          this.getCompanies()
        })
      },
      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.title = ''
        this.content = ''
        this.userId = ''
        this.author = ''
        this.companyId = ''
        this.type = 'news'
        this.contentType = 'text'
        // this.selectedCategories = []
        this.selectedCountries = []
        this.date = new Date().toISOString().split('T')[0]
        this.outstanding = false
        this.active = false
        this.image = null
        this.expiredAt = null
        this.address = ''
        this.typeCover = ''
        this.shortDesc = ''
        this.modality = ''
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.internalAuthor = true
        this.title = this.item.title
        this.content = this.item.content
        this.type = this.item.type ? this.item.type : 'news'
        this.contentType = this.item.contentType
        this.selectedCountries = this.item.countries
        this.date = new Date(this.item.date).toISOString().split('T')[0]
        this.expiredAt =
          this.item.expired_at && this.item.expired_at !== '0000-00-00 00:00:00'
            ? new Date(this.item.expired_at).toISOString().split('T')[0]
            : this.item.expired_at
        this.outstanding = this.item.outstanding
        this.active = this.item.active
        this.image = null
        if (!this.item.user_id) {
          this.internalAuthor = false
          this.externalAuthor = true
          this.author = this.item.author
        } else {
          this.userId = this.item.user_id
          this.companyId = this.item.company_id
          this.getAssignedUser(this.item.user_id)
        }
        this.address = this.item.address
        this.typeCover = this.item.typecover
        this.shortDesc = this.item.shortdesc
        this.modality = this.item.modality
        this.$modal.show('crudModal')
      },
      validate() {
        this.$refs.formCompany.validate()
      },
      reset() {
        this.$refs.formCompany.reset()
      },
      async createItem() {
        let formData = new FormData()
        this.loading = true
        this.outstanding = this.outstanding ? 1 : 0
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }
        if (this.image) {
          formData.append('file', this.image)
        }
        if (this.internalAuthor) {
          formData.append('user_id', this.userId.id)
          if (this.companyId !== {} && this.companyId && this.companyId.id) {
            formData.append('company_id', this.companyId.id)
          }
        } else {
          formData.append('author', this.author)
        }
        formData.append('fileType', 'cover')
        formData.append('title', this.title)
        formData.append('content', this.content)
        formData.append('type', this.type)
        formData.append('contentType', this.contentType)
        formData.append('outstanding', this.outstanding)
        formData.append('active', this.active)
        formData.append('date', this.date)
        if (this.expiredAt && this.expiredAt !== '0000-00-00 00:00:00') {
          formData.append('expired_at', this.expiredAt)
        }
        if (this.modality) {
          formData.append('modality', this.modality)
        }
        if (this.shortDesc) {
          formData.append('shortdesc', this.shortDesc)
        }
        if (this.address) {
          formData.append('address', this.address)
        }
        if (this.typeCover) {
          formData.append('typecover', this.typeCover)
        }
        this.selectedCountries.forEach((country) => {
          formData.append('countries[]', country.id)
        })
        axios.post('publications', formData, this.config).then((response) => {
          this.page = 1
          this.getPublications()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.loading = true
        let formData = new FormData()
        this.outstanding = this.outstanding ? 1 : 0
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }
        if (this.image) {
          formData.append('file', this.image)
          if (this.item.cover) {
            formData.append('fileId', this.item.cover.id)
          }
        }
        formData.append('fileType', 'cover')
        formData.append('_method', 'patch')
        formData.append('title', this.title)
        if (this.internalAuthor) {
          formData.append('user_id', this.userId.id)
          if (this.companyId !== {} && this.companyId && this.companyId.id) {
            formData.append('company_id', this.companyId.id)
          }
        } else {
          formData.append('author', this.author)
        }
        formData.append('content', this.content)
        formData.append('type', this.type)
        formData.append('contentType', this.contentType)
        formData.append('outstanding', this.outstanding)
        formData.append('active', this.active)
        formData.append('date', this.date)
        if (this.expiredAt && this.expiredAt !== '0000-00-00 00:00:00') {
          formData.append('expired_at', this.expiredAt)
        }
        if (this.modality) {
          formData.append('modality', this.modality)
        }
        if (this.shortDesc) {
          formData.append('shortdesc', this.shortDesc)
        }
        if (this.address) {
          formData.append('address', this.address)
        }
        if (this.typeCover) {
          formData.append('typecover', this.typeCover)
        }
        this.selectedCountries.forEach((country) => {
          formData.append('countries[]', country.id)
        })
        axios
          .post('publications/' + this.item.id, formData, this.config)
          .then((response) => {
            this.page = 1
            this.getPublications()
            this.$modal.hide('crudModal')
          })
      },

      handleImageAdded(file, Editor, cursorLocation, resetUploader) {
        if (this.flow === 'edit') {
          var formData = new FormData()
          formData.append('file', file)
          formData.append('fileType', 'image')
          formData.append('_method', 'patch')

          axios
            .post(process.env.VUE_APP_FIAF_API + 'publications/' + this.item.id, formData)
            .then((response) => {
              let url = response.data.publication.lastest_upload.route // Get url from response
              Editor.insertEmbed(cursorLocation, 'image', url)
              resetUploader()
            })
        } else {
          this.$swal({
            title: 'Ups...',
            text: 'Para agregar imágenes primero debes crear la publicación. ',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'De acuerdo',
          })
          resetUploader()
        }
      },

      async deleteItem(item) {
        this.loading = true
        axios.delete('publications/' + item.id).then((response) => {
          this.page = 1
          this.items = []
          this.getPublications()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getPublications()
      },

      async deleteFile(id) {
        axios.delete('upload-management/delete-upload/' + id, this.config).then(() => {})
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
  .ql-editor .ql-video {
    display: block;
    max-width: 100%;
    width: 70%;
    margin: auto;
    height: 300px;
  }
  .v-textarea textarea {
    height: 80px;
  }
  .text-box strong {
    font-weight: 600;
  }
</style>
